import _isNaN from 'lodash/isNaN';
import _isNil from 'lodash/isNil';

export const isNumberOrStringifiedNumber = (value: number | string | null | undefined): boolean =>
  !_isNil(value) && !_isNaN(Number(value));

export const formatNumber = ({
  value,
  locale,
  maximumFractionDigits,
  maximumSignificantDigits,
  notation,
  compactDisplay,
  currencyDisplay,
  unit,
  padDecimalPlaces,
}: {
  value: number;
  locale?: string;
  maximumFractionDigits?: number;
  maximumSignificantDigits?: number;
  notation?: 'standard' | 'scientific' | 'engineering' | 'compact' | 'text';
  compactDisplay?: 'short' | 'long';
  currencyDisplay?: 'code' | 'symbol' | 'narrowSymbol' | 'name';
  unit?: string;
  padDecimalPlaces?: boolean;
}) => {
  try {
    if (notation === 'text') {
      return new Intl.NumberFormat(locale, {
        useGrouping: false,
      }).format(value);
    }

    return new Intl.NumberFormat(locale, {
      maximumFractionDigits,
      maximumSignificantDigits,
      minimumFractionDigits: padDecimalPlaces ? maximumFractionDigits : undefined,
      notation,
      compactDisplay,
      style: unit ? 'unit' : 'decimal',
      currencyDisplay,
      unit,
    }).format(value);
  } catch (e) {
    return String(value);
  }
};
