import type { PageVariableState } from '@unifyapps/defs/types/pageVariable';

export function adaptPageVariablesForContext(
  pageVariables: Record<string, PageVariableState | undefined>,
) {
  // Page variable is stored in store as:
  // {
  //   [variableName: string]: {
  //     initialValue: any;
  //     value: any;
  //     type: string;
  //   };
  // }
  // but we are showing it in the input data pill picker as
  // {
  //   [variableName: string]: value
  // }
  // so we are adapting the page variables to the format that is used in the input data pill picker
  return Object.entries(pageVariables).reduce<Record<string, unknown>>((acc, [key, value]) => {
    if (value) {
      acc[key] = value.value;
    }
    return acc;
  }, {});
}
