'use client';

import { clsx } from 'clsx';
import { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import useDebugging from '../../hooks/useDebugging';
import { useGetDeviceDetailsInStateStore } from '../../stores/GlobalStateStore';
import { useInterfaceStore } from '../../stores/InterfaceStore';
import { useRegistryContext } from '../RegistryProvider';
import PageLayout, { PageFooter, PageHeader, PageWorkspace } from '../../../components/PageLayout';
import { getPageLayout } from './getPageBody';

function InterfacePage({
  className,
  workspaceClassName,
  ignorePageRefSettingInStore,
}: {
  className?: string;
  workspaceClassName?: string;
  ignorePageRefSettingInStore?: boolean;
}) {
  const layout = useInterfaceStore().use.page.layout();
  const name = useInterfaceStore().use.page.name();

  const { header, body, footer } = getPageLayout(layout);
  const BlockRenderer = useRegistryContext().registry.getBlockRenderer();
  const { device } = useGetDeviceDetailsInStateStore();
  const { setInterfacePageEl } = useInterfaceStore().use.actions();

  useDebugging();

  return (
    <>
      <title>{name || 'Page'}</title>
      <PageLayout
        className={className}
        ref={ignorePageRefSettingInStore ? undefined : setInterfacePageEl}
      >
        {header ? (
          <PageHeader className="ua-page-header" headerClassName="!p-0 !basis-auto">
            {header ? <BlockRenderer blockId={header} key={header} /> : null}
          </PageHeader>
        ) : null}
        {body ? (
          <PageWorkspace
            className={clsx(
              'overflow-y-auto',
              {
                'rounded-t-none': !header,
                'rounded-b-3xl': Boolean(footer),
                '!me-0': (!header && !footer) || device === DeviceVariantType.MOBILE,
              },
              workspaceClassName,
            )}
          >
            {body ? <BlockRenderer blockId={body} key={body} /> : null}
          </PageWorkspace>
        ) : null}
        {footer ? (
          <PageFooter className="ua-page-footer">
            {footer ? <BlockRenderer blockId={footer} key={footer} /> : null}
          </PageFooter>
        ) : null}
      </PageLayout>
    </>
  );
}

export default InterfacePage;
