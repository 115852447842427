import { useMemo } from 'react';
import { Op } from '@unifyapps/network/generated/models/op';
import type { AggregationQuery } from '@unifyapps/network/generated/models/aggregationQuery';
import type { Filter } from '@unifyapps/network/generated/models/filter';
import type { Projection } from '@unifyapps/network/generated/models/projection';
import type { Sort } from '@unifyapps/network/generated/models/sort';
import compact from 'lodash/compact';
import type { UAEntityTableComponentType } from '@unifyapps/defs/blocks/Table/UAEntityTable/types';
import { AggregationQueryBuilder } from '../utils/builders/aggregationQuery';

interface UseAggregationQueryBuilderParams {
  filter?: Filter | null;
  search?: string | null;
  sort?: Sort[] | null;
  timeFilter?: UAEntityTableComponentType['metadata']['timeFilter'] | null;
}

export const adaptSearchToFilter = (
  search?: string | null,
  searchField?: string,
): Filter | undefined => {
  return search ? { op: 'ICONTAINS', field: searchField ?? 'lcName', values: [search] } : undefined;
};

export const buildAggregationQuery = (
  tableConfiguration: UAEntityTableComponentType,
  { filter, search, sort, timeFilter }: UseAggregationQueryBuilderParams = {},
): AggregationQuery => {
  const aggregationQuery = new AggregationQueryBuilder();

  aggregationQuery.withEntityType(tableConfiguration.metadata.entityType);
  aggregationQuery.withGroup(tableConfiguration.metadata.group);

  if (tableConfiguration.metadata.includeTotalHits) {
    aggregationQuery.withIncludeTotalHits(tableConfiguration.metadata.includeTotalHits);
  }

  // need to verify this change
  const projections: Projection[] = [...(tableConfiguration.metadata.projections || [])];

  const useAlias = tableConfiguration.metadata.useAlias;
  if (tableConfiguration.metadata.identifier) {
    projections.push({
      name: tableConfiguration.metadata.identifier,
      ...(useAlias ? { alias: tableConfiguration.metadata.identifier } : {}),
    });
  }

  tableConfiguration.columns.forEach((schemaColumn) => {
    if (schemaColumn.fieldKey) {
      projections.push({
        name:
          typeof schemaColumn.fieldKey === 'string'
            ? schemaColumn.fieldKey
            : //@ts-expect-error -- need to convert prop type to DataTableComponentType
              //eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- need to convert prop type to DataTableComponentType
              (schemaColumn.fieldKey?.path as string),
        ...(schemaColumn.aggregationFunction
          ? { aggregationFunction: schemaColumn.aggregationFunction }
          : {}),
        ...(useAlias
          ? {
              alias: schemaColumn.alias?.[schemaColumn.fieldKey],
            }
          : {}),
      });
    }
    if (schemaColumn.fieldKeys) {
      schemaColumn.fieldKeys.forEach((fieldKey) => {
        projections.push({
          name: fieldKey,
          ...(useAlias ? { alias: schemaColumn.alias?.[fieldKey] } : {}),
          ...(schemaColumn.aggregationFunction
            ? { aggregationFunction: schemaColumn.aggregationFunction }
            : {}),
        });
      });
    }
  });

  if (filter || tableConfiguration.metadata.filter || search) {
    aggregationQuery.withFilter({
      op: Op.AND,
      values: compact([
        filter,
        tableConfiguration.metadata.filter,
        adaptSearchToFilter(search, tableConfiguration.metadata.searchField),
      ]),
    });
  }

  if (timeFilter) {
    const adaptedTimeFilter = {
      since: timeFilter.since ? new Date(timeFilter.since).getTime() : undefined,
      until: timeFilter.until ? new Date(timeFilter.until).getTime() : undefined,
    };
    aggregationQuery.withTimeFilter(adaptedTimeFilter);
  }

  if (sort || tableConfiguration.metadata.sorts) {
    aggregationQuery.withSorts(sort || tableConfiguration.metadata.sorts || []);
  }

  // evaluate using unique values here if using metadata.projections
  aggregationQuery.withProjections(projections);

  return aggregationQuery.build();
};

/**
 * Hook to generate the query for the table based on the table configuration.
 * @param tableConfiguration - The configuration for the table, including column properties and metadata
 * @param filter - The filter to apply to the query
 */
export function useAggregationQueryBuilder(
  tableConfiguration: UAEntityTableComponentType,
  { filter, search, sort, timeFilter }: UseAggregationQueryBuilderParams = {},
): AggregationQuery {
  return useMemo(
    () => buildAggregationQuery(tableConfiguration, { filter, search, sort, timeFilter }),
    [filter, search, sort, tableConfiguration, timeFilter],
  );
}
