import { createContext, useContext, useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useBlocksInActiveInterfacePage, useInterfaceStore } from '../../stores/InterfaceStore';
import { useDataSourceRecords } from '../../stores/DataSourceRecordStore';
import { useRegistryContext } from '../RegistryProvider';
import type { OnActionType } from '../ActionsProvider/context';
import { getBlocksMethods } from './utils/getBlocksMethods';
import { getDataSourcesMethods } from './utils/getDataSourcesMethods';
import { getPageVariablesMethods } from './utils/getPageVariablesMethods';

export const InterfaceEntitiesMethodsContext = createContext<{
  blocks: Record<string, unknown>;
  dataSources: Record<string, unknown>;
  pageVariables: Record<string, unknown>;
}>({
  blocks: {},
  dataSources: {},
  pageVariables: {},
});

export const useInterfaceEntitiesMethods = () => {
  const context = useContext(InterfaceEntitiesMethodsContext);
  return context;
};

function InterfaceEntitiesMethodsProvider({
  onAction,
  children,
}: {
  onAction: OnActionType;
  children: React.ReactNode;
}) {
  const { registry } = useRegistryContext();

  const blocks = useBlocksInActiveInterfacePage();
  const dataSources = useDataSourceRecords();
  const pageVariables = useInterfaceStore().use.page.pageVariables();

  const value = useMemo(() => {
    return {
      blocks: getBlocksMethods({ blocks, onAction, blockRegistry: registry }),
      dataSources: getDataSourcesMethods({ dataSources, onAction }),
      pageVariables: getPageVariablesMethods({ pageVariables, onAction }),
    };
  }, [blocks, dataSources, onAction, pageVariables, registry]);

  return (
    <InterfaceEntitiesMethodsContext.Provider value={value}>
      {children}
    </InterfaceEntitiesMethodsContext.Provider>
  );
}

export default InterfaceEntitiesMethodsProvider;
