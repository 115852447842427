import _reduce from 'lodash/reduce';

export const getCombinedPageVariables = ({
  pageVariables,
  pageVariablesMethods,
}: {
  pageVariables: Record<string, unknown>;
  pageVariablesMethods: Record<string, unknown>;
}) => {
  return _reduce(
    pageVariables,
    (acc, pageVariableValue, pageVariableId) => {
      acc[pageVariableId] = {
        value: pageVariableValue,
        ...(pageVariablesMethods[pageVariableId] as Record<string, unknown>),
      };

      return acc;
    },
    {},
  );
};
