import React, { Children, forwardRef } from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import { Box } from '@unifyapps/ui/components/Box';
import type { ReactNode, ReactElement } from 'react';
import { clsx } from 'clsx';
import Header from '../Header';
import { PageHeader } from './PageHeader';
import { PageWorkspace } from './PageWorkspace';
import { PageFooter } from './PageFooter';

function PageLayoutWithTabs({
  title,
  tabsNode,
  children,
}: {
  title: string;
  tabsNode: ReactNode;
  children: ReactNode;
}) {
  return (
    <BasePageLayout>
      <PageHeader>
        <Header>
          <Header.Heading>
            <Typography as="h3" color="text-primary" variant="text-lg" weight="semi-bold">
              {title}
            </Typography>
          </Header.Heading>
        </Header>
      </PageHeader>
      <PageWorkspace>
        {tabsNode}
        <Box className="ua-layout-manager px-2xl min-h-0 flex-1">{children}</Box>
      </PageWorkspace>
    </BasePageLayout>
  );
}

const BasePageLayout = forwardRef(function BasePageLayout(
  { children, className }: { children: ReactNode; className?: string },
  ref?: React.ForwardedRef<HTMLDivElement>,
) {
  const childrenArr = Children.toArray(children) as ReactElement[];
  const header = childrenArr.find((child: ReactElement) => child.type === PageHeader);
  const workspace = childrenArr.find((child: ReactElement) => child.type === PageWorkspace);
  const footer = childrenArr.find((child: ReactElement) => child.type === PageFooter);

  return (
    <Stack
      className={clsx('relative h-full overflow-hidden', className)}
      direction="column"
      ref={ref}
    >
      {header}
      {workspace}
      {footer}
    </Stack>
  );
});

const PageLayout = BasePageLayout as typeof BasePageLayout & {
  WithTabs: typeof PageLayoutWithTabs;
};
PageLayout.WithTabs = PageLayoutWithTabs;

export default PageLayout;
