import type { BlockType } from '@unifyapps/defs/types/block';
import _reduce from 'lodash/reduce';
import { ActionType } from '@unifyapps/defs/types/action';
import type { OnActionType } from '../../ActionsProvider/context';
import type BlocksRegistry from '../../RegistryProvider/BlocksRegistry';

export const getBlocksMethods = ({
  blocks,
  onAction,
  blockRegistry,
}: {
  blocks: BlockType[];
  onAction: OnActionType;
  blockRegistry: BlocksRegistry;
}) => {
  return _reduce(
    blocks,
    (acc, block) => {
      const methods = blockRegistry.getBlockControlMethods(block.component.componentType, block);

      acc[block.id] = _reduce(
        methods,
        (blockAcc, method) => {
          blockAcc[method.id] = (methodPayload: Record<string, unknown>) => {
            void onAction({
              action: {
                actionType: ActionType.ControlBlockMethod,
                payload: {
                  blockId: block.id,
                  methodName: method.id,
                  methodPayload,
                },
              },
              actionContext: {},
            });
          };

          return blockAcc;
        },
        {},
      );

      return acc;
    },
    {},
  );
};
