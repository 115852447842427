import _reduce from 'lodash/reduce';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import { RUN_TIME_BLOCK_KEY } from '../../../utils/runTimeBlocks';

export function getCombinedBlockMethodsInBlocksState({
  blocksMethods,
  blocksState,
}: {
  blocksState: Record<string, BlockStateUnionType | undefined>;
  blocksMethods: Record<string, unknown>;
}) {
  const updatedBlockState = _reduce(
    blocksMethods,
    (acc, blockMethod, blockId) => {
      acc[blockId] = {
        ...(acc[blockId] as Record<string, unknown>),
        ...(blockMethod as Record<string, unknown>),
      } as BlockStateUnionType;

      return acc;
    },
    {
      ...blocksState,
    } as Record<string, BlockStateUnionType | undefined>,
  );

  //in order to get runtime blocks, we need to filter out the blocks that have RUN_TIME_BLOCK_KEY
  const runtimeBlocks = _reduce(
    { ...updatedBlockState },
    (acc, value, key) => {
      if (key.startsWith(RUN_TIME_BLOCK_KEY)) {
        acc[key] = value;
      }

      return acc;
    },
    {},
  );

  return { ...updatedBlockState, runtimeBlocks } as Record<
    string,
    BlockStateUnionType | undefined
  > & {
    runtimeBlocks: Record<string, BlockStateUnionType | undefined>;
  };
}
