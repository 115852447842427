import _values from 'lodash/values';
import type { BlockId, BlockType, ComponentsUnionType } from '@unifyapps/defs/types/block';
import { useMemo } from 'react';
import type { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import { InterfacePageEntityComponentType } from '@unifyapps/defs/types/page';
import InterfacePageHelper from '../../helper/InterfacePage';
import { useGlobalStateStore } from '../GlobalStateStore';
import { useInterfaceStore } from './InterfaceStoreProvider';
import { type InterfaceStoreType } from './types';

export const useGetInterfaceStoreState = () => {
  return {
    getInterfaceStoreState: useInterfaceStore().getState,
  };
};

export const useBlocksInActiveInterfacePage = () => {
  const blocks = useBlocksFromPage();
  return useMemo(() => _values(blocks), [blocks]);
};

export const useActivePropertyPanelHistory = () => {
  const propertyPanelHistory = useInterfaceStore().use.propertyPanel().history;
  return {
    activeState: propertyPanelHistory[propertyPanelHistory.length - 1],
    historyLength: propertyPanelHistory.length,
  };
};

export const useIsActiveInterfacePageDirty = () => {
  const activeInterfacePageId = useInterfaceStore().use.activeInterfacePageId();
  const dirtyPages = useInterfaceStore().use.dirtyPages();
  return dirtyPages.has(activeInterfacePageId);
};

export const useActiveBlockFromPage = <T = ComponentsUnionType>() => {
  const blockId = useInterfaceStore().use.activeBlockId();
  return useBlockFromPage<T>({ blockId });
};

export const getActiveBlockFromPage = (
  store: { getState: () => InterfaceStoreType },
  device: DeviceVariantType,
) => {
  const state = store.getState();

  const activeBlockId = state.activeBlockId;
  const activeInterfacePage = state.interfacePages[state.activeInterfacePageId];

  const deviceBlock = InterfacePageHelper.getDeviceBlock(
    activeInterfacePage,
    device,
    activeBlockId,
  );
  const baseDeviceBlock = InterfacePageHelper.getBaseDeviceBlock(
    activeInterfacePage,
    activeBlockId,
  );

  return deviceBlock ?? baseDeviceBlock;
};

export const useBlockFromPage = <T = ComponentsUnionType>({ blockId }: { blockId: BlockId }) => {
  const baseBlock = useInterfaceStore().use.block.fromBase({ blockId });
  const { device } = useGlobalStateStore().use.deviceDetails();
  const deviceBlock = useInterfaceStore().use.block.fromDevice({ blockId, device });

  return (deviceBlock ?? baseBlock) as BlockType<T> | undefined;
};

export const useBlocksFromPage = () => {
  const baseBlocks = useInterfaceStore().use.blocks.fromBaseDevice();
  const { device } = useGlobalStateStore().use.deviceDetails();
  const deviceBlocks = useInterfaceStore().use.blocks.fromDevice({
    deviceVariant: device,
  });

  return useMemo(() => {
    // Most of the time deviceBlocks will be undefined, so we can return baseBlocks directly
    if (!deviceBlocks) {
      return baseBlocks;
    }

    return {
      ...baseBlocks,
      ...deviceBlocks,
    };
  }, [baseBlocks, deviceBlocks]);
};

export const useInterfaceModules = () => {
  const interfacePages = useInterfaceStore().use.pages();
  const activeInterfacePageId = useInterfaceStore().use.activeInterfacePageId();

  return useMemo(() => {
    return _values(interfacePages).filter(
      (interfacePage) =>
        InterfacePageHelper.getComponentType(interfacePage) ===
          InterfacePageEntityComponentType.MODULE && interfacePage.id !== activeInterfacePageId,
    );
  }, [activeInterfacePageId, interfacePages]);
};
