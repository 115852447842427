import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import { isEmptyBlock } from '../../utils/block';

export const getPageLayout = (
  layout?: NonNullable<InterfacePageEntity['properties']>['layout'],
) => {
  const header = isEmptyBlock(layout?.header) ? undefined : layout?.header;
  const body = isEmptyBlock(layout?.body) ? undefined : layout?.body;
  const footer = isEmptyBlock(layout?.footer) ? undefined : layout?.footer;

  return {
    header,
    body,
    footer,
  };
};
