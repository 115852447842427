import type { ReactNode } from 'react';
import { lazy } from 'react';
import { clsx } from 'clsx';

const NavBreadcrumbsHeader = lazy(
  () => import('../../navigation/breadcrumbs/NavBreadcrumbsHeader'),
);

export function PageHeader({
  children,
  showBreadcrumbs,
  headerClassName,
  breadCrumbsClassName,
  className,
}: {
  children?: ReactNode;
  showBreadcrumbs?: boolean;
  headerClassName?: string;
  breadCrumbsClassName?: string;
  className?: string;
}) {
  return (
    <header className={clsx('flex w-full flex-col', className)}>
      {showBreadcrumbs ? <NavBreadcrumbsHeader className={breadCrumbsClassName} /> : null}
      {children ? (
        <div
          className={clsx(
            'ua-page-header px-2xl py-lg pb-md basis-topbarHeight flex shrink-0 items-center',
            headerClassName,
          )}
        >
          {children}
        </div>
      ) : null}
    </header>
  );
}
