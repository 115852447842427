import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import _reduce from 'lodash/reduce';
import { ActionType } from '@unifyapps/defs/types/action';
import type { OnActionType } from '../../ActionsProvider/context';

export const getDataSourcesMethods = ({
  dataSources,
  onAction,
}: {
  dataSources: Record<string, DataSourceEntity | undefined>;
  onAction: OnActionType;
}) => {
  return _reduce(
    dataSources,
    (acc, dataSource) => {
      if (!dataSource) return acc;

      acc[dataSource.id] = {
        trigger: () => {
          void onAction({
            action: {
              actionType: ActionType.ControlDataSource,
              payload: {
                method: 'trigger',
                dataSourceId: dataSource.id,
              },
            },
            actionContext: {},
          });
        },
      };

      return acc;
    },
    {},
  );
};
