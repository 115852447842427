import type { InterfaceEntity } from '@unifyapps/defs/types/interface';

function getInterfaceRecordForState(interfaceRecord: InterfaceEntity) {
  const interfaceRecordForState = {
    id: interfaceRecord.id,
    name: interfaceRecord.properties.name,
    deviceDetails: interfaceRecord.properties.deviceDetails,
    metadata: {
      logo: interfaceRecord.properties.metadata.logo,
    },
  };

  return interfaceRecordForState;
}

export default getInterfaceRecordForState;
