import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import _reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { RUN_TIME_BLOCK_KEY } from '../../../utils/runTimeBlocks';

export const getCombinedBlockState = ({
  blocksState = {},
  blocksRuntimeState,
}: {
  blocksState?: Record<string, BlockStateUnionType | undefined> & {
    runtimeBlocks?: Record<string, BlockStateUnionType | undefined>;
  };
  blocksRuntimeState: Record<string, Record<string, unknown>>;
}) => {
  const newBlocksState = {};

  if (isEmpty(blocksRuntimeState)) {
    return blocksState;
  }

  for (const [blockId, blockRuntimeState] of Object.entries(blocksRuntimeState)) {
    const copyBlockState = blocksState[blockId] ? { ...blocksState[blockId] } : undefined;
    const newCopy = getCombinedEachBlockState({
      blockState: copyBlockState,
      blockRuntimeState,
    });
    newBlocksState[blockId] = newCopy as BlockStateUnionType;
  }

  //in order to get runtime blocks, we need to filter out the blocks that have RUN_TIME_BLOCK_KEY
  const runtimeBlocks = _reduce(
    { ...newBlocksState },
    (acc, value, key) => {
      if (key.startsWith(RUN_TIME_BLOCK_KEY)) {
        acc[key] = value;
      }

      return acc;
    },
    {},
  );

  return {
    ...blocksState,
    ...newBlocksState,
    runtimeBlocks: {
      ...blocksState.runtimeBlocks,
      ...runtimeBlocks,
    },
  };
};

// Function to combine each block's state with runtime data
const getCombinedEachBlockState = ({
  blockState,
  blockRuntimeState,
}: {
  blockState: BlockStateUnionType | undefined;
  blockRuntimeState: Record<string, unknown>;
}): Partial<BlockStateUnionType> => {
  // Create a copy of the blockState
  if (!blockState) {
    return {
      context: blockRuntimeState,
    };
  }

  // Initialize context if it does not exist
  if (!blockState.context) {
    blockState.context = {};
  }

  // Set the context to blocksRuntimeState
  blockState.context = blockRuntimeState;

  return blockState;
};
