import type { PropsWithChildren } from 'react';
import React, { Children } from 'react';
import { clsx } from 'clsx';
import Stack from '@unifyapps/ui/_components/Stack';
import { Box } from '@unifyapps/ui/components/Box';
import type { IconButtonProps } from '@unifyapps/ui/components/IconButton';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import ArrowNarrowLeft from '@unifyapps/icons/outline/ArrowNarrowLeft';
import type { TypographyProps } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import { HeadingWrapper } from './HeadingWrapper';

function Actions(props: PropsWithChildren) {
  const { children } = props;
  return <>{children}</>;
}

function BackButton(
  props: Pick<IconButtonProps, 'className' | 'component' | 'onClick' | 'href' | 'variant'>,
) {
  return <IconButton Icon={ArrowNarrowLeft} color="neutral" size="md" variant="soft" {...props} />;
}

function BackButtonContainer(props: PropsWithChildren) {
  return props.children;
}

function Heading(props: PropsWithChildren<{ className?: string }>) {
  const { className, children } = props;
  return (
    <Stack alignItems="center" className={clsx('gap-md min-w-0 flex-1', className)} direction="row">
      {children}
    </Stack>
  );
}

function SubHeading(props: PropsWithChildren<{ className?: string }>) {
  const { className, children } = props;
  return (
    <Stack className={clsx('min-w-0 flex-1', className)} direction="row">
      {children}
    </Stack>
  );
}

function Middle(props: PropsWithChildren<{ className?: string }>) {
  const { className, children } = props;
  return <Box className={clsx('w-fit', className)}>{children}</Box>;
}

function HeadingTypography(props: PropsWithChildren<{ variant?: TypographyProps['variant'] }>) {
  return (
    <Typography
      as="h3"
      className="truncate"
      color="text-primary"
      variant={props.variant ?? 'display-xs'}
      weight="semi-bold"
    >
      {props.children}
    </Typography>
  );
}

function SubHeadingTypography(
  props: PropsWithChildren<{
    variant?: TypographyProps['variant'];
    weight?: TypographyProps['weight'];
  }>,
) {
  return (
    <Typography
      as="span"
      className="truncate"
      color="text-tertiary"
      variant={props.variant ?? 'text-xs'}
      weight={props.weight ?? 'medium'}
    >
      {props.children}
    </Typography>
  );
}

function Header(props: PropsWithChildren<{ className?: string }>) {
  const { children } = props;
  const childrenArr = Children.toArray(children) as React.ReactElement[];
  const actionsSlot = childrenArr.find((child: React.ReactElement) => child.type === Actions);
  const backButtonSlot = childrenArr.find(
    (child: React.ReactElement) => child.type === BackButton || child.type === BackButtonContainer,
  );
  const headingSlot = childrenArr.find((child: React.ReactElement) => child.type === Heading);
  const middleSlot = childrenArr.find((child: React.ReactElement) => child.type === Middle);
  const subHeadingSlot = childrenArr.find((child: React.ReactElement) => child.type === SubHeading);

  return (
    <Stack
      alignItems="center"
      className={clsx(
        'gap-xl w-full flex-shrink-0',
        subHeadingSlot ? 'h-14' : 'h-9',
        props.className,
      )}
      direction="row"
      justifyContent="space-between"
    >
      {backButtonSlot || headingSlot || subHeadingSlot ? (
        <Stack
          alignItems="center"
          className={clsx('gap-xs pe-2xl min-w-0 flex-1', { 'pb-xxs': Boolean(subHeadingSlot) })}
          direction="row"
        >
          {backButtonSlot}
          <HeadingWrapper>
            {headingSlot}
            {subHeadingSlot}
          </HeadingWrapper>
        </Stack>
      ) : null}
      {middleSlot ? <Box className="w-fit">{middleSlot}</Box> : null}
      {actionsSlot ? (
        <Stack
          alignItems="center"
          className={clsx('gap-md', { 'flex-1': Boolean(middleSlot) })}
          direction="row"
          justifyContent="end"
        >
          {actionsSlot}
        </Stack>
      ) : null}
    </Stack>
  );
}

const HeaderNamespace = Object.assign(Header, {
  Actions,
  BackButton,
  BackButtonContainer,
  Heading,
  Middle,
  SubHeading,
  Components: {
    HeadingTypography,
    SubHeadingTypography,
  },
});

export default HeaderNamespace;
