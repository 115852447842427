import _reduce from 'lodash/reduce';
import type { QueryRequestResult } from '../../../stores/GlobalStateStore';

export const getCombinedDataSourceState = ({
  dataSourcesState,
  dataSourceMethods,
}: {
  dataSourcesState: Record<string, QueryRequestResult | undefined>;
  dataSourceMethods: Record<string, unknown>;
}) => {
  return _reduce(
    dataSourceMethods,
    (acc, dataSourceMethod, dataSourceId) => {
      acc[dataSourceId] = {
        ...(acc[dataSourceId] as QueryRequestResult),
        ...(dataSourceMethod as Record<string, unknown>),
      };

      return acc;
    },
    { ...dataSourcesState } as Record<string, unknown>,
  );
};
